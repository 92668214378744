import config from "@config";

export const updateCurrency = async ({ currency, price }) => {
  const request = await fetch(`${config.api_local}/exchange/?amount=${price}&currency=${currency}`, {
    headers: {
      "Content-Type": "application/json"
    }
  });
  const { data } = await request.json();

  if (!data) throw new Error("Can't change amount");

  return data;
};
