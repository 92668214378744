import { Formik, Form } from "formik";
import { useTranslation } from "gatsby-plugin-react-i18next";
import swal from "sweetalert";
import { ContactSchema, DEFAULT_CONTACT_VALUES } from "@schemas";
import { sendContactEmail } from "@services";
import { useGetCountry } from "@hooks";

import Input from "@components/Input";

export default function ContactForm() {
  const { t } = useTranslation("formik");
  const { phoneCode } = useGetCountry();

  const formTranslations = {
    required: t("required"),
    requiredTerms: t("required-terms"),
    requiredEmail: t("required-email")
  };

  const handleSubmit = async (values, actions) => {
    try {
      actions.setSubmitting(true);

      const { error } = await sendContactEmail({
        values: { ...values, phoneCode }
      });
      if (error) throw new Error(error);

      actions.resetForm(DEFAULT_CONTACT_VALUES);
      swal({
        title: "Success!",
        text: t("contact-success", { ns: "translation" }),
        icon: "success"
      });
    } catch (error) {
      swal("Error!", t(error.message, { ns: "errors" }), "error");
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <section className="contact" id="contact">
      <div className="contact__container">
        <Formik
          initialValues={DEFAULT_CONTACT_VALUES}
          validationSchema={ContactSchema(formTranslations)}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="contact__form" id="contact">
              <div className="contact__content">
                <h2 className="contact__title">{t("title")}</h2>
                <p className="contact__subtitle">{t("subtitle")}</p>
                <div className="contact__form-grid">
                  <Input
                    label="name"
                    name="name"
                    type="text"
                    placeholder={t("name")}
                  />
                  <Input
                    label="email"
                    name="email"
                    type="email"
                    placeholder={t("email")}
                  />
                  <Input
                    label="phone"
                    name="phone"
                    type="text"
                    placeholder={t("phone")}
                  />

                  <Input
                    label="Message"
                    name="subject"
                    type="text"
                    placeholder={t("message")}
                  />
                </div>
                <button
                  type="submit"
                  className="contact__form-button"
                  disabled={isSubmitting}
                >
                  {t("connect")}
                </button>
                <p className="contact__advice">{t("advice")}</p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
}
