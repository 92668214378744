/* eslint-disable operator-linebreak */
import { useRef } from "react";
import ReviewsImage from "@images/reviews-principal.png";
import PeopleOne from "@images/emily.png";
import PeopleTwo from "@images/ethan.png";
import PeopleThree from "@images/sophia.png";
import IconPrev from "@icons/prev.svg";
import IconNext from "@icons/next.svg";
import "@stylesComponents/Reviews.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

export default function Reviews() {
  const { t } = useTranslation("reviews");
  const carouselRef = useRef(null);

  const handleCarousel = (event) => {
    const carousel = carouselRef.current;
    const zeroMovement = carousel.clientWidth * 2;
    if (carousel && event.target) {
      const movementCalculation = (carousel.firstChild.clientWidth / 3) * 2;
      const prev =
        carousel.scrollLeft <= 0
          ? zeroMovement
          : carousel.scrollLeft - movementCalculation;
      const next =
        carousel.scrollLeft >= zeroMovement
          ? 0
          : carousel.scrollLeft + movementCalculation;
      const direction = event.target.dataset.to === "prev";

      carousel.scrollTo({
        top: carousel.scrollTop,
        left: direction ? prev : next,
        behavior: "smooth"
      });
    }
  };

  return (
    <section className="reviews">
      <div className="reviews__content">
        <h2 className="reviews__title">{t("title")}</h2>
        <h2 className="reviews__paragraph">{t("subtitle")}</h2>
        <div className="reviews__box">
          <div className="reviews__actions">
            <button
              type="button"
              className="reviews__action"
              onClick={handleCarousel}
              data-to="prev"
            >
              <img src={IconPrev} alt="" className="reviews__action-icon" />
            </button>
            <button
              type="button"
              className="reviews__action reviews__action--next"
              onClick={handleCarousel}
              data-to="next"
            >
              <img src={IconNext} alt="" className="reviews__action-icon" />
            </button>
          </div>
          <div ref={carouselRef} className="reviews__people-carousel">
            <article className="reviews__people-box">
              <p className="reviews__people-text">{t("reviewOne")}</p>
              <div className="reviews__people-user">
                <img src={PeopleOne} alt="" className="reviews__people-image" />
                <p className="reviews__people-name">Sophia Williams</p>
              </div>
            </article>
            <article className="reviews__people-box">
              <p className="reviews__people-text">{t("reviewTwo")}</p>
              <div className="reviews__people-user">
                <img src={PeopleTwo} alt="" className="reviews__people-image" />
                <p className="reviews__people-name">Ethan Brown</p>
              </div>
            </article>
            <article className="reviews__people-box">
              <p className="reviews__people-text">{t("reviewThree")}</p>
              <div className="reviews__people-user">
                <img
                  src={PeopleThree}
                  alt=""
                  className="reviews__people-image"
                />
                <p className="reviews__people-name">Emily Taylor</p>
              </div>
            </article>
          </div>
          <img src={ReviewsImage} alt="" className="reviews__image" />
        </div>
      </div>
    </section>
  );
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
