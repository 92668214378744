/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/display-name */
import { forwardRef } from "react";
import { useField } from "formik";

const withField = (InputComponent) =>
  forwardRef(({ label, ...props }, ref) => {
    const [field, meta] = useField(props);
    const error = Boolean(meta.touched && meta.error);

    return (
      <label
        htmlFor={props.id || props.name}
        ref={ref}
        data-readonly={props.readOnly}
        data-error={error}
        data-error-msg={meta.error}
        className="input"
      >
        <InputComponent
          error={error}
          field={field}
          meta={meta}
          label={label}
          {...props}
        />
        <p className="input__text" title={meta.error} data-error={error}>
          {error ? ` ${meta.error}` : null}
        </p>
      </label>
    );
  });

export default withField;
